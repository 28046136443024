<script setup>
import {computed, ref, watch} from "vue";
import {useStore} from "vue2-helpers/vuex";
import Draggable from 'vuedraggable'
import constants from "../../../../../constants/constants";
import enterpriseService from "@/services/enterprise.service";
import {ACCOUNT_TYPE_FREE} from "@/constants/accountTypes";
import CustomizeDashboard from "@/views/enterprise/home/topBar/customize/CustomizeDashboard.vue";

const store = useStore();
const showModal = ref(false);
const layout = ref([]);
const customizeDashboardModal = ref(null);

const initLayout = computed(() => store.getters['homepage/layout']);

const defaultLayout = computed(() => {
    let layout = store.state.homepage.defaultLayout;

    if (store.getters.settings.disable_tasks) {
        layout = layout.filter(item => item !== 'tasks')
    }

    return layout;
});

const hiddenSections = computed(() => defaultLayout.value
    .filter(section =>
        (store.state.accountType !== ACCOUNT_TYPE_FREE || !['tasks','searches','trending','categories'].includes(section))
        && !layout.value.some(item => item === section)
    )
);

const startupsSearchesCombined = computed(() => {
    return layout.value.includes('startups') && layout.value.includes('searches');
});

watch(layout, () => {
    store.commit('homepage/changeLayout', layout.value);
    saveSettings();
}, {deep: true});

function customizeDashboard() {
    showModal.value = false;
    customizeDashboardModal.value.open();
}

async function saveSettings() {
    await enterpriseService.saveHomepageSettings(layout.value);
}

function addSection(section) {
    if (section === 'startups' && layout.value.includes('searches')) {
        let index = layout.value.indexOf('searches');
        layout.value.splice(index, 0, 'startups');
    } else if (section === 'searches' && layout.value.includes('startups')) {
        let index = layout.value.indexOf('startups');
        layout.value.splice(index + 1, 0, 'searches');
    } else {
        layout.value.push(section);
    }
}

function hideSection(section) {
    layout.value = layout.value.filter(item => item !== section);
}

function getItems(section) {
    if (section === 'startups' && startupsSearchesCombined.value) {
        return ['startups', 'searches'];
    }

    return [section];
}

function open() {
    showModal.value = true;
    layout.value = [...initLayout.value];
}

defineExpose({
    open
})
</script>

<template>
    <div>
        <b-modal id="customize-homepage-modal" v-model="showModal" modal-class="modal-w-456" hide-footer>
            <template #modal-title>
                Customize Homepage
            </template>

            <p class="modal-text">Drag sections to reorder, hide to remove them from your homepage. <b>Changes will affect all your users.</b></p>

            <div class="customize-section">
                <Draggable v-model="layout" class="drag-content">
                    <template
                        v-for="section in layout"
                    >
                        <div
                            v-if="!(section === 'searches' && startupsSearchesCombined)"
                            :key="section"
                            class="form__grabbing drag-n-drop-item"
                        >
                            <div v-for="subSection in getItems(section)" :key="subSection">
                                <div class="drag-n-drop-item__content">
                                    <h5 class="heading5">{{constants.homepageSections[subSection]}}</h5>

                                    <a
                                        v-if="subSection === 'dashboard'"
                                        class="link-edit"
                                        @click="customizeDashboard"
                                    >
                                        Edit
                                    </a>
                                </div>

                                <footer class="drag-n-drop-item__footer">
                                    <a class="icon-hide" @click="hideSection(subSection)">
                                        Hide Section
                                    </a>
                                </footer>
                            </div>
                        </div>
                    </template>
                </Draggable>

                <div v-if="hiddenSections.length">
                    <h5 class="heading5 heading5--secondary">Hidden sections</h5>

                    <div class="drag-n-drop-item" v-for="section in hiddenSections" :key="section">
                        <div class="drag-n-drop-item__content">
                            {{constants.homepageSections[section]}}
                        </div>

                        <footer class="drag-n-drop-item__footer">
                            <a class="icon-show" @click="addSection(section)">
                                Show Section
                            </a>
                        </footer>
                    </div>
                </div>
            </div>
        </b-modal>

        <CustomizeDashboard
            ref="customizeDashboardModal"
            @closed="showModal = true"
        />
    </div>
</template>

<style scoped>
    .drag-content .drag-n-drop-item {
        @media (min-width: 480px) {
            display: flex;
        }
        &>div {
            flex: 1;
        }
        &>:nth-child(2) {
            .drag-n-drop-item__content::before {
                content: none;
            }

            border-left: 1px solid #ddd;
        }
    }
</style>
