<script setup>
import Vue, {ref, computed, watch, provide, reactive, nextTick, toRefs} from "vue";
import {useStore} from "vue2-helpers/vuex";
import trackersService from "@/services/trackers.service";
import constants from "@/constants/constants";
import {ACCOUNT_TYPE_EVENT_LIMITED, ACCOUNT_TYPE_FREE, ACCOUNT_TYPE_PREMIUM} from "@/constants/accountTypes";
import {triggerUpgrade} from "@/composables/useStoreActions";
import useForm from "@/composables/useForm";
import {trackerTypes} from "@/constants/trackers";
import SubmitterFields from "@/components/trackers/trackerEdit/SubmitterFields.vue";
import trackersFoldersService from "@/services/trackers-folders.service";
import SelectType from "@/components/trackers/trackerEdit/SelectType.vue";
import Pipeline from "@/components/trackers/trackerEdit/Pipeline.vue";
import SelectTemplate from "@/components/trackers/trackerEdit/SelectTemplate.vue";
import POC from "@/components/trackers/trackerEdit/POC.vue";
import Permissions from "@/components/trackers/trackerEdit/Permissions.vue";
import CustomFields from "@/components/trackers/trackerEdit/CustomFields.vue";
import TimeLine from "@/components/trackers/trackerEdit/Timeline.vue";
import Sharing from "@/components/trackers/trackerEdit/Sharing.vue";
import Categories from "@/components/trackers/trackerEdit/Categories.vue";
import Details from "@/components/trackers/trackerEdit/Details.vue";
import Footer from "@/components/trackers/trackerEdit/Footer.vue";
import Settings from "@/components/trackers/trackerEdit/Settings.vue";

const props = defineProps(['folderId', 'folderType']);
const {folderId, folderType} = toRefs(props);
const emit = defineEmits(['trackerSaved']);
const store = useStore();

const id = ref(null);
const savedTrackerId = ref(null);
const showModal = ref(false);

const tracker = reactive({
    name: '',
    folder: null,
    categories: [],
    description: '',
    pipeline: null,
    timelinePreset: null,
    startDate: null,
    endDate: null,
    postDate: null,
    type: '',
    enablePipeline: true,
    defaultView: 'pipeline',
    reviewsEnabled: 1,
    needCompleteProfile: false,
    notifyStartups: true,
    hideDetailsUntilSignIn: false,
    onlyReferral: false,
    internalAccessById: false,
    questions: [],
    reviewerFields: [],
    submitterFields: [],
    diligenceFields: [],
    permissions: [],
    userPermissions: [],
    termsAndConditions: "",
    accessType: "private",
    ecosystems: [],
    emailStages: [],
    enableNotifications: true,
    templateId: null,
});

const {resetForm, populateForm} = useForm(tracker);

const disabledTypeSelection = ref(false);
const step = ref(1);
const selectTemplateStep = ref(false);
const submitterFieldsBlock = ref(null);
const selectTemplateBlock = ref(null);
const pipelineBlock = ref(null);
const timeline = ref([null, null]);
const loading = ref(false);
const initDiligenceFields = ref([]);
const initReferral = ref(false);
const newFilesFieldsCount = ref(0);

const showPrevStepButton = computed(() => step.value === 2 && !id.value && store.state.accountType !== ACCOUNT_TYPE_FREE && !disabledTypeSelection.value);
const canEditInfo = computed(() => !id.value || tracker.userPermissions.includes('tracker-edit'));

watch(() => tracker.enablePipeline, (value) => {
    if (!value) {
        tracker.defaultView = 'list';
    }
});

watch(() => tracker.onlyReferral, (value) => {
    if (initReferral.value === null) {
        initReferral.value = value;
    } else if (initReferral.value && !value && !confirm("Disabling this option will remove the referral url.\n" +
        "Do you want to proceed?")) {

        nextTick(() => {
            tracker.onlyReferral = true;
        })
    }
});

function prevStep() {
    step.value--;
}

function close() {
    showModal.value = false;
    document.body.classList.remove('modal-open')
}

function setType(type) {
    tracker.type = type;

    if ([trackerTypes.ECOSYSTEMS, trackerTypes.POC].includes(type)) {
        tracker.enablePipeline = false;
    }

    tracker.templateId = null;
    step.value++;
}

async function init() {
    resetForm();

    selectTemplateStep.value = false;
    initDiligenceFields.value = [];
    initReferral.value = false;

    if (id.value) {
        step.value = 2;
        disabledTypeSelection.value = true;

        const {data} = await trackersService.getTrackerEdit(id.value);
        populateForm(data);

        initDiligenceFields.value = tracker.diligenceFields.map(item => item.id);
        initReferral.value = tracker.onlyReferral;
    } else {
        if ([ACCOUNT_TYPE_FREE, ACCOUNT_TYPE_EVENT_LIMITED].includes(store.state.accountType) || store.getters.isEcosystem) {
            setType(trackerTypes.PRIVATE);
            disabledTypeSelection.value = true;
            step.value = 2;
        } else if (folderType.value === trackerTypes.PUBLIC) {
            setType(trackerTypes.PUBLIC);
            disabledTypeSelection.value = true;
            step.value = 2;
        }

        if (folderId.value) {
            const {data} = await trackersFoldersService.getInfo(folderId.value);
            tracker.permissions = data.permissions;
            tracker.userPermissions = data.user_permissions;
        } else {
            const {data} = await trackersService.getNewTrackerInfo();
            tracker.userPermissions = data.user_permissions;
        }
    }
}

async function save() {
    if (!submitterFieldsBlock.value.validateFields()) {
        return;
    }

    let removedFields = !!initDiligenceFields.value.filter(initFieldId => !tracker.diligenceFields.some(field => field.id === initFieldId)).length;

    if (!removedFields || confirm("Fields you removed from tracker will cause their values removing for existing startups. Do you want to proceed?")) {
        let form = {
            ...tracker,
            updatedEmailStages: pipelineBlock.value.emailStages,
            updatedEmailTemplates: pipelineBlock.value.emailTemplates,
            submitterFields: tracker.submitterFields.filter(field => submitterFieldsBlock.value.visibleFields.includes(field.field.id)),
        }

        loading.value = true;

        try {
            const {data} = await trackersService.save(id.value, form);
            savedTrackerId.value = data;

            let newFilesFields = tracker.submitterFields.filter(field => field.value.files.some(file => file.id === 0));
            newFilesFieldsCount.value = newFilesFields.length;

            if (newFilesFieldsCount.value) {
                newFilesFields.forEach(field => {
                    let formData = new FormData();

                    field.value.files.forEach(file => {
                        if (file.id === 0) {
                            formData.append('file[]', file);
                        }
                    });

                    trackersService.saveSubmitterFiles(savedTrackerId.value, field.field.id, formData).then(() => {
                        newFilesFieldsCount.value--;

                        if (!newFilesFieldsCount.value) {
                            saveComplete();
                        }
                    });
                });
            } else {
                saveComplete();
            }
        } catch (e) {
            console.log(e);
            loading.value = false;
        }
    }
}

function saveComplete() {
    loading.value = false;
    emit('trackerSaved', savedTrackerId.value);
    close();

    Vue.notify({
        group: 'app',
        duration: 5000,
        type: 'success',
        text: id.value ? "Tracker updated" : "Tracker created" + '<a class="btn btn-outline-success btn-notify btn-md" href="/trackers/' + savedTrackerId.value + '" target="_blank">Open Tracker</a>'
    })
}

async function open(trackerId = 0, type = null) {
    id.value = trackerId;

    if (type) {
        disabledTypeSelection.value = true;
        step.value = 2;
    } else {
        step.value = 1;
    }

    let canOpen = true;

    if (store.state.accountType !== ACCOUNT_TYPE_PREMIUM && !id.value) {
        const {data} = await trackersService.getCount();

        if (data >= constants.enterpriseLimits.trackers[store.state.accountType]) {
            triggerUpgrade(store, 'trackers');
            canOpen = false;
        }
    }

    if (canOpen) {
        showModal.value = true;
        document.body.classList.add('modal-open');
        init();

        if (type) {
            tracker.type = type;
        }
    }
}

provide('tracker', tracker);
provide('trackerId', id);
provide('folderId', folderId.value);
provide('canEditInfo', canEditInfo);
provide('timeline', timeline);

defineExpose({
    open,
});

</script>

<template>
    <div v-if="showModal" class="modal-edit-tracker modal-edit-tracker--sub modal-edit-creation-tracker">
        <header class="modal-header">
            <button type="button" aria-label="Close" class="close" @click="close"></button>
            <b-button v-if="showPrevStepButton" variant="primary" size="lg" @click="prevStep" class="back-link"></b-button>
        </header>

        <template v-if="step === 1">
            <SelectType @setType="setType" />
        </template>

        <div v-else class="modal-sub">
            <div class="modal-sub__inner">
                <SelectTemplate
                    ref="selectTemplateBlock"
                    :submitter-fields-list="submitterFieldsBlock?.fieldsList"
                    :select-template-step.sync="selectTemplateStep"
                />

                <div v-show="!selectTemplateStep">
                    <h1 class="modal__title mb-1">Add Tracker Details</h1>

                    <Details
                        :selected-template="selectTemplateBlock?.selectedTemplate || null"
                        :has-templates="selectTemplateBlock?.hasTemplates || false"
                        :select-template-step.sync="selectTemplateStep"
                    />

                    <SubmitterFields ref="submitterFieldsBlock"/>

                    <Pipeline ref="pipelineBlock"/>

                    <POC v-if="tracker.type === trackerTypes.POC"/>

                    <Permissions/>

                    <CustomFields/>

                    <TimeLine/>

                    <Categories/>

                    <Sharing/>

                    <Settings/>
                </div>

                <Footer
                    v-if="!selectTemplateStep"
                    :loading="loading"
                    @save="save"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
