<script setup>
import searchService from "@/services/search.service";
import AutoCompleter from "@/components/AutoCompleter.vue";
import {ref, computed, defineProps, defineEmits, watch, inject, nextTick} from 'vue';
import PromptBar from "@/components/search/PromptBar.vue";
import EquationBar from "@/components/search/EquationBar.vue";
import {useStore} from "vue2-helpers/vuex";
import {constants} from "@/views/search/searchConstants";
import {getLastId} from "@/helpers/equation";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";
import SelectTrackers from "@/components/manage/SelectTrackers.vue";
import trackersCompaniesService from "@/services/trackers-companies.service";
import SearchActions from "@/views/search/SearchActions.vue";
import {useRoute} from "vue2-helpers/vue-router";

const aiInputMinWidth = 400;

const props = defineProps(['gridParams','customView','searchString','aiLoading','aiFailed','query','promptQuery','suggestedAiParams',
    'searchParams','showRadarSwitcher','isRadarView','searchType','searchData','canUpdateSavedSearch','elasticQuery']);

const {gridParams} = props;

const emit = defineEmits(['searchStringChanged','keywordAdded','applyAIQueryParams',
    'getAiQueryParams','ruleDeleted','changeView','deleteAllRules','saveSearch','updateSavedSearch','getList']);

const store = useStore();
const route = useRoute();

const aiMode = ref(false);
const aiText = ref("");
const aiTextForModal = ref("");
const geminiAIModel = ref(false);
const aiInputWidth = ref(aiInputMinWidth);
const showAiFailedAlert = ref(false);
const topBar = ref(null);
const aiTextSpan = ref(null);
const aiModal = ref(null);
const selectTrackersModal = ref(null);

// Computed
// -------------------------------------------------------------------------------------------------
const canAISearch = computed(() =>
    props.searchType === constants.SEARCH_TYPE_STARTUPS
    && store.getters.isEnterprise
    && !props.customView
    && !store.getters.settings.disable_ai
);

const allQuery = computed(() => ({
    main: props.query,
    prompt: props.promptQuery
}));

const searchString = computed({
    get: () => props.searchString,
    set: (value) => emit('update:searchString', value)
});

// Watchers
// -------------------------------------------------------------------------------------------------
watch(() => props.aiFailed, (value) => {
    if (value) {
        showAiFailedAlert.value = true;
    }
});

watch(() => props.suggestedAiParams, (value) => {
    if (Object.keys(value?.keywords).length) {
        aiMode.value = false;
        aiText.value = "";
    }
});

watch(() => store.getters.settings, () => {
    applySettings();
}, {immediate: true});

watch(aiMode, (value) => {
    if (value) {
        if (searchString.value.length) {
            aiText.value = searchString.value;
        }
    } else {
        if (aiText.value.length) {
            searchString.value = aiText.value;
        }
    }
});

watch(aiText, () => {
    nextTick(() => {
        const aiInputMaxWidth = topBar.value.clientWidth;
        const spanWidth = Math.ceil(aiTextSpan.value.offsetWidth * 0.92) + 75;

        if (spanWidth > aiInputMinWidth) {
            if (spanWidth < aiInputMaxWidth) {
                aiInputWidth.value = spanWidth;
            } else {
                aiInputWidth.value = aiInputMaxWidth;
            }
        } else {
            aiInputWidth.value = aiInputMinWidth;
        }
    })
});

// Methods
// -------------------------------------------------------------------------------------------------

const deleteRule = inject('deleteRule');
const invertRule = inject('invertRule');
const toggleCondition = inject('toggleCondition');

function applySettings() {
    aiMode.value = canAISearch.value && !store.getters.settings.search_ai_default_off;
    let aiPrompt = route.query.ai_prompt;

    if (aiPrompt && aiMode.value) {
        aiText.value = aiPrompt;
        aiTextSubmitted();
    }
}

async function addToTrackers(trackers) {
    let trackersIds = trackers.map(item => item.id)

    if (gridParams.selectedAllResults) {
        await trackersCompaniesService.addCompaniesByQuery(allQuery.value, props.searchParams, gridParams.sort, trackersIds);
    } else {
        await trackersCompaniesService.addCompanies(trackersIds, gridParams.selectedRows);
    }

    emit('getList')
}

function editPrompt() {
    aiMode.value = true;

    nextTick(() => {
        aiText.value = props.promptQuery.params.text;
    })
}

function convertPromptToEquation() {
    let id = parseInt(getLastId(props.query));

    props.promptQuery.rules.forEach(rule => {
        ++id;
        rule.id = id;
        props.query.rules.push(rule);
    });

    props.promptQuery.rules = [];
}

function copyAiResponseToClipboard() {
    navigator.clipboard.writeText(props.suggestedAiParams.raw_response);
}

function aiTextSubmitted() {
    aiTextForModal.value = aiText.value;
    emit('getAiQueryParams', aiText.value, 'OpenAI', true);
}

function applyAIQueryParams(type) {
    aiModal.value.hide();
    emit('applyAIQueryParams', type);
}

function deleteAllRules() {
    aiMode.value = canAISearch.value && !store.getters.settings.search_ai_default_off;
    emit('deleteAllRules');
}

function getAIQueryParamsForModal() {
    aiText.value = aiTextForModal.value;
    emit('getAiQueryParams', aiText.value, geminiAIModel.value ? 'GeminiAI' : 'OpenAI');
}

</script>

<template>
    <div class="topbar topbar--search">
        <div class="topbar__inner">
            <div class="topbar__key" ref="topBar">
                <header class="topbar__key__header pl-0">
                    <!-- Showing -->
                    <select
                        v-if="gridParams.totalRowsCount >= gridParams.pageLength"
                        v-model="gridParams.pageLength"
                        class="topbar__key__select"
                    >
                        <option
                            v-for="page in [10,25,50,100,200]"
                            :value="page"
                            :key="page"
                        >
                            {{page}}
                        </option>
                    </select>

                    <template v-else>{{gridParams.totalRowsCount}}</template>

                    of {{stringHelper.formatNumber(gridParams.totalRowsCount)}} results
                </header>

                <div class="form-search-primary-wrapper">
                    <template v-if="customView">
                        <input
                            v-model="searchString"
                            class="form-search-primary"
                            placeholder="Search by Name or Description"
                            v-debounce:500ms.lock="() => emit('searchStringChanged', searchString)"
                        />
                    </template>

                    <template v-else>
                        <div v-if="aiMode">
                            <input
                                :disabled="aiLoading"
                                class="form-search-primary dynamic-width-input"
                                :style="{ width: aiInputWidth + 'px' }"
                                type="text"
                                placeholder="Describe what you are looking for."
                                v-model.trim="aiText"
                                @keyup.enter="aiTextSubmitted"
                            />
                        </div>

                        <template v-else>
                            <AutoCompleter
                                :search-string.sync="searchString"
                                ref="keywordAutocompleter"
                                placeHolder="What are you looking for?"
                                inputClass="form-search-primary"
                                :update-function= "searchService.suggest"
                                :clearOnSubmit = true
                                @submitted="(keyword) => emit('keywordAdded', keyword)"
                            />
                        </template>

                        <span class="hidden-span" ref="aiTextSpan">{{ aiText }}</span>
                    </template>

                    <template v-if="canAISearch">
                        <div
                            v-if="showAiFailedAlert"
                            class="ai-failed"
                            @click="showAiFailedAlert = false"
                            v-click-outside="() => {showAiFailedAlert = false}"
                        >
                            <div>
                                <h5 class="heading5 heading5--secondary">Let's try that again</h5>
                                <p>Provide more details or re-phrase your query</p>
                            </div>
                        </div>

                        <span
                            v-if="aiLoading"
                            class="ai__spinner"
                        >
                            <span class="loader"></span>
                            Loading...
                        </span>

                        <div class="ai-switcher" v-else>
                            <input
                                v-model="aiMode"
                                id="ai-switcher"
                                type="checkbox"
                            />
                            <label
                                for="ai-switcher"
                                class="ai-switcher__label"
                            >
                                <span class="ai-switcher__label__inner"></span>
                            </label>
                        </div>
                    </template>
                </div>

                <footer class="topbar__key__footer">
                    <template v-if="!customView">
                        <div
                            v-if="promptQuery.rules.length && !aiLoading"
                            class="key__filter mb-3"
                            style="display:flex; align-items: baseline; width:100%"
                        >
                            <PromptBar
                                :query="promptQuery"
                                @rule-deleted="deleteRule"
                                @invert-rule="invertRule"
                            />
                        </div>

                        <div
                            v-if="query.rules.length && !aiLoading"
                            class="mb-3"
                            style="display:flex; flex-wrap: wrap; align-items: baseline; width:100%"
                        >
                            <!-- <span class="topbar__key__label">Filtered by:</span> -->
                            <EquationBar
                                :query="query"
                                @rule-deleted="deleteRule"
                                @invert-rule="invertRule"
                                @toggle-condition="toggleCondition"
                            />
                        </div>
                    </template>

                    <span v-else class="topbar__key__label align-self-end">Displaying only startups added by your company</span>

                    <ul class="topbar__side actions-list" style="top: 5px;">
                        <template v-if="!customView">
                            <li v-if="(query.rules.length || promptQuery.rules.length)">
                                <a
                                    class="topbar__side__unload"
                                    @click="deleteAllRules"
                                >
                                    Clear All
                                </a>
                            </li>

                            <li v-if="promptQuery.params.text?.length">
                                <a
                                    class="topbar__side__edit"
                                    @click="editPrompt">
                                    Edit Search Prompt
                                </a>
                            </li>

                            <li v-if="promptQuery.rules.length">
                                <a
                                    class="topbar__side__boolean"
                                    @click="convertPromptToEquation">
                                    Switch to Boolean
                                </a>
                            </li>

                            <li v-if="showRadarSwitcher">
                                <a
                                    class="topbar__side__radar"
                                    @click="emit('changeView', 'radar')"
                                >
                                    View As Radar
                                </a>
                            </li>

                            <li v-else-if="isRadarView">
                                <a
                                    class="topbar__side__list"
                                    @click="emit('changeView', 'list')"
                                >
                                    View As List
                                </a>
                            </li>

                            <li v-if="query.rules.length || promptQuery.rules.length">
                                <a
                                    class="topbar__side__save"
                                    @click="emit('saveSearch')"
                                >
                                    Save Search
                                </a>
                            </li>

                            <li v-if="canUpdateSavedSearch">
                                <a
                                    class="topbar__side__save"
                                    @click="emit('updateSavedSearch')"
                                >
                                    Update Saved Search
                                </a>
                            </li>
                        </template>

                        <li
                            v-if="searchType === constants.SEARCH_TYPE_STARTUPS && gridParams.selectedRows.length"
                            class="actions-list__add"
                        >
                            <a @click="selectTrackersModal.open()">Add To Trackers</a>
                        </li>

                        <SearchActions
                            :grid-params="gridParams"
                            :elastic-query="elasticQuery"
                            :search-type="searchType"
                            :all-query="allQuery"
                            :searchParams="searchParams"
                            :suggested-ai-params="suggestedAiParams"
                            @getList="emit('getList')"
                            @openAiModal="aiModal.show()"
                        />
                    </ul>
                </footer>
            </div>
        </div>

        <b-modal ref="aiModal" modal-class="modal-w-md" title="Gen AI Search (beta)">
            <b-form-textarea class="form-control--mid" v-model="aiTextForModal"></b-form-textarea>

            <div class="content-switcher">
                <span>Open AI</span>
                <div class="content-switcher__slider">
                    <input type="checkbox" id="content-switcher__slider" v-model="geminiAIModel">
                    <label for="content-switcher__slider"></label>
                </div>

                <span>Gemini</span>
            </div>

            <div class="mt-3" v-if="suggestedAiParams">
                <template v-if="suggestedAiParams.rewritten_query">
                    <br>
                    <h5>Rewritten Query:</h5>
                    <div>
                        {{suggestedAiParams.rewritten_query}}
                    </div>
                </template>

                <template v-if="suggestedAiParams.exclusions.length">
                    <br>
                    <h5>Exclusions:</h5>
                    <div v-for="(exclusion, index) in suggestedAiParams.exclusions" :key="index">
                        <span class="tags-list__tag">{{exclusion}}</span>
                    </div>
                </template>

                <template v-if="suggestedAiParams.locations.length">
                    <br>
                    <h5>Filter "Locations":</h5>
                    <div v-for="location in suggestedAiParams.locations" :key="location.value">
                        <span class="tags-list__tag">{{location.name}}</span>
                    </div>
                </template>

                <template v-if="suggestedAiParams.funding_types.length">
                    <br>
                    <h5>Filter "Funding Types":</h5>
                    <div v-for="fundingType in suggestedAiParams.funding_types" :key="fundingType">
                        <span class="tags-list__tag">{{fundingType}}</span>
                    </div>
                </template>

                <template v-if="suggestedAiParams.funding_ranges.length">
                    <br>
                    <h5>Filter "Funding Range":</h5>
                    <div v-for="fundingRange in suggestedAiParams.funding_ranges" :key="JSON.stringify(fundingRange)">
                        <span class="tags-list__tag">{{fundingRange}}</span>
                    </div>
                </template>

                <template v-if="suggestedAiParams.revenue_ranges.length">
                    <br>
                    <h5>Filter "Revenue Range":</h5>
                    <div v-for="revenueRange in suggestedAiParams.revenue_ranges" :key="revenueRange">
                        <span class="tags-list__tag">{{revenueRange}}</span>
                    </div>
                </template>

                <template v-if="suggestedAiParams.last_funding_date.length">
                    <br>
                    <h5>Filter "Last Funding Date":</h5>
                    <div>
                        <span class="tags-list__tag">{{suggestedAiParams.last_funding_date[0]}} - {{suggestedAiParams.last_funding_date[1]}}</span>
                    </div>
                </template>

                <template v-if="suggestedAiParams.employees_count?.length">
                    <br>
                    <h5>Filter "Employees Count":</h5>
                    <div v-for="employeesCount in suggestedAiParams.employees_count" :key="JSON.stringify(employeesCount)">
                        <span class="tags-list__tag">{{employeesCount}}</span>
                    </div>
                </template>

                <br>
                <h5>Suggested Keywords:</h5>
                <div v-for="(words, mainWord) in suggestedAiParams.keywords" :key="mainWord">
                    <div class="mt-3">
                        <b>{{mainWord}}: </b>
                        <span class="tags-list__tag" v-for="(word, index) in words" :key="index">{{word}}, </span>
                    </div>
                </div>

                <div class="mt-3">
                    <a class="link" @click="copyAiResponseToClipboard">Copy AI response</a>
                </div>
            </div>

            <template #modal-footer>
                <div style="text-align: center">
                    <PrimaryButton
                        caption="Get Keywords"
                        loading-caption="Loading..."
                        :loading="aiLoading"
                        @click="getAIQueryParamsForModal"
                    />

                    <div class="mt-2" v-if="suggestedAiParams && Object.keys(suggestedAiParams.keywords).length">
                        <b-button variant="primary" class="ml-2" size="lg" @click="applyAIQueryParams('query')">Apply Query</b-button>
                        <b-button variant="primary" class="ml-2" size="lg" @click="applyAIQueryParams('keywords')">Apply Keywords</b-button>
                    </div>
                </div>
            </template>
        </b-modal>

        <SelectTrackers
            ref="selectTrackersModal"
            :trackers-list="store.getters.trackersList"
            @trackersSelected="addToTrackers"
        />
    </div>
</template>

<style scoped>
    .hidden-span {
        visibility: hidden;
        white-space: nowrap;
        position: absolute;
    }
</style>
