<script setup>
import {ref, inject} from "vue";
import useGrid from "@/composables/useGrid";
import TablePager from "@/components/TablePager.vue";
import {trackerTypesLabels} from "@/constants/trackers";
import useCurrentPageData from "@/composables/useCurrentPageData";
import ViewAnswers from "@/components/trackers/ViewAnswers.vue";
import trackersCompanies from "@/services/trackers-companies.service";

const emit = defineEmits(['addToTracker','trackerRemoved']);

const {gridParams, gridMethods} = useGrid(getTrackers, 5);
const getPageItems = useCurrentPageData();
const trackers = ref([]);
const showModal = ref(false);
const trackersLoaded = ref(false);
const viewAnswersModal = ref(null);
const companyType = inject('companyType');
const companyId = inject('companyId');
const companyName = inject('companyName');
const getTrackersIndexList = inject('getTrackersIndexList');

async function getTrackers() {
    trackers.value = await getPageItems(getTrackersIndexList, gridParams);
    trackersLoaded.value = true;
}

function addToTracker() {
    showModal.value = false;
    emit('addToTracker');
}

async function removeCompany(trackerId) {
    if (!confirm('Remove company from this tracker?')) {
        return;
    }

    await trackersCompanies.removeCompanies(trackerId, [companyId]);
    getTrackers();
    emit('trackerRemoved');
}

function open() {
    if (!trackersLoaded.value) {
        gridParams.currentPage = 1;
        getTrackers();
    }

    showModal.value = true;
}

function updateTrackers() {
    if (trackersLoaded.value) {
        getTrackers();
    }
}

defineExpose({open, updateTrackers});
</script>

<template>
    <div>
        <b-modal v-model="showModal" title="Trackers" hide-footer modal-class="modal-trackers modal-w-xxl">
            <a class="link link--add-data" @click="addToTracker">Add To A Tracker</a>

            <ul class="list-bordered">
                <li v-for="tracker in trackers" :key="tracker.id">
                    <div class="list-bordered__inner">
                        <header class="list-bordered__header">
                            <a class="mr-auto" :href="`/trackers/${tracker.id}`" target="_blank">{{tracker.name}}</a>

                            <div class="list-bordered__header__tags-list">
                                <div :class="'button-light button-light--' + companyType">{{tracker.companies_count}}</div>
                                <div v-if="tracker.type !== 'ecosystems'" :class="`button-light button-light--${tracker.type}`">{{trackerTypesLabels[tracker.type]}}</div>
                                <div v-if="tracker.stage" class="button-light button-light--tracker">{{tracker.stage}}</div>
                            </div>
                        </header>

                        <div class="list-bordered__description" v-html="tracker.description"></div>

                        <footer class="list-bordered__footer" v-if="tracker.has_submitted_answers">
                            {{companyName}} added answers to the startup fields in this tracker. 
                            <a class="link link--view" @click="viewAnswersModal.open(tracker.id, companyId)">View Answers</a>
                        </footer>
                    </div>

                    <div class="modal-manage__task__dropdown" 
                        v-if="tracker.user_can_delete_startups && tracker.type !== 'public'"
                    >
                        <b-dropdown class="dropdown-original icons-list dropdown-small" boundary="viewport">
                            <b-dropdown-item class="icons-list__delete" @click="removeCompany(tracker.id)">
                                Remove
                            </b-dropdown-item>
                        </b-dropdown>
                    </div>
                </li>
            </ul>

            <TablePager
                v-if="trackers.length"
                :pages-count="gridParams.pagesCount"
                :init-page="gridParams.currentPage"
                @changePage="gridMethods.changePage"
            />
        </b-modal>

        <ViewAnswers ref="viewAnswersModal"/>
    </div>
</template>

<style scoped>

</style>
