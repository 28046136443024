<script setup>
import {getLogo} from "@/helpers/logo";
import AddToTrackers from "@/components/manage/SelectTrackers.vue";
import SimilarStartups from "@/components/startups/SimilarStartups.vue";
import {useStore} from "vue2-helpers/vuex";
import {ref, inject, watch, computed, nextTick, provide} from "vue";
import ExportPdf from "@/components/export/ExportPdf.vue";
import exportsService from "@/services/exports.service";
import fileHelper from "@/helpers/file";
import accountTypes, {ACCOUNT_TYPE_PREMIUM} from "@/constants/accountTypes";
import TrackersListModal from "@/components/trackers/TrackersListModal.vue";
import TasksList from "@/components/tasks/TasksListModal.vue";
import StartChatPopup from "@/views/messages/StartChatPopup.vue";
import trackersCompaniesService from "@/services/trackers-companies.service";
import startupsService from "@/services/startups.service";
import VueMultiselect from "vue-multiselect";

const {commonData} = defineProps(['commonData']);
const emit = defineEmits(['getLatestActivities','reportIncorrectData','inviteCompany']);
const companyId = inject('companyId');
const companyType = inject('companyType');
const getTasksCountMethod = inject('getTasksCount');
const getTrackersIdsMethod = inject('getTrackersIds');
const getAvailableRecipientsMethod = inject('getAvailableRecipients');
const store = useStore();

const trackersIds = ref(null);
const addToTrackersModal = ref(null);
const similarStartupsModal = ref(null);
const exportPdfModal = ref(null);
const trackersListModal = ref(null);
const tasksListModal = ref(null);
const startChatModal = ref(null);
const availableRecipients = ref([]);
const reopenTrackersModal = ref(false);
const tasksCount = ref(commonData.tasks_count);
const showActions = ref(false);
const collabTrackerInfo = ref(null);
const collabStage = ref(null);
const watchCollabUpdate = ref(true);

const isStartup = companyType === 'startup';
const allTrackersList = computed(() => companyType === accountTypes.ECOSYSTEM ? store.getters.ecosystemTrackersList : store.getters.trackersList);

watch(() => store.getters.isEnterprise, (value) => {
    if (value) {
        getTrackersIds();
        getAvailableRecipients();
    }
}, {immediate: true});

watch(collabStage, (value) => {
    if (watchCollabUpdate.value) {
        updateCollabTrackerStage(value?.id ?? null);
    }
});

async function getTrackersIds() {
    const {data} = await getTrackersIdsMethod();
    trackersIds.value = data;

    if (isStartup) {
        await getCollabTrackerInfo();
    }
}

async function getAvailableRecipients() {
    const {data} = await getAvailableRecipientsMethod();
    availableRecipients.value = data;
}

async function getCollabTrackerInfo() {
    watchCollabUpdate.value = false;

    const {data} = await startupsService.getCollabTrackerInfo(companyId);
    collabTrackerInfo.value = data;

    if (collabTrackerInfo.value) {
        collabStage.value = collabTrackerInfo.value.funnel_stages.find(item => item.id === collabTrackerInfo.value.current_stage);

        nextTick(() => {
            watchCollabUpdate.value = true;
        })
    }
}

async function updateCollabTrackerStage(stage) {
    await startupsService.updateCollabTrackerStage(companyId, stage);
    await getCollabTrackerInfo();
}

async function addToTrackers(addedTrackers) {
    let trackersIds = addedTrackers.map(item =>item.id);

    await trackersCompaniesService.addCompanies(trackersIds, [companyId]);
    getTrackersIds();
    trackersListModal.value.updateTrackers();
    emit('getLatestActivities');
}

async function exportPPT(advanced = false) {
    const {data} = await exportsService.generateStartupsPpt(advanced, [companyId]);
    fileHelper.download(data, commonData.name + '.pptx')
}

async function exportContacts() {
    const {data} = await exportsService.exportCompaniesContacts(companyType, [companyId]);
    fileHelper.download(data, 'contacts.csv');
}

function showTasks() {
    tasksListModal.value.open();
}

function addTrackerFromModal() {
    reopenTrackersModal.value = true;
    addToTrackersModal.value.open();
}

function addToTrackersClosed() {
    if (reopenTrackersModal.value) {
        reopenTrackersModal.value = false;
        trackersListModal.value.open();
    }
}

async function getTasksCount() {
    const {data} = await getTasksCountMethod();
    tasksCount.value = data;
}

function trackersClicked() {
    if (trackersIds.value.length) {
        trackersListModal.value.open();
    } else {
        addToTrackersModal.value.open();
    }
}

provide('tasksCount', tasksCount);
</script>

<template>
    <div class="topbar topbar--primary">
        <div class="image-block-wrapper">
            <div class="image-block image-block--lg">
                <img :src="getLogo(commonData.logo_url)" alt="img"/>
            </div>

            <div>
                <div class="d-flex mb-2 pt-lg-3">
                    <h2 class="heading2 mb-0">{{commonData.name}}</h2>

                    <a
                        v-if="store.getters.isEnterprise
                        && !(companyType === accountTypes.ECOSYSTEM && store.getters.settings.disable_ecosystems_trackers)
                        && trackersIds !== null"
                        class="counter"
                        @click="trackersClicked"
                    >
                        {{trackersIds.length ? trackersIds.length : '+'}}
                    </a>
                </div>

                <div v-if="collabTrackerInfo" class="collab-tracker-info">
                    <div>
                        {{collabTrackerInfo.funnel_name}}:
                    </div>

                    <VueMultiselect
                        v-if="collabTrackerInfo.can_change_stage"
                        class="multiselect-mid ml-2 select-collab-tracker-info"
                        v-model="collabStage"
                        :options="collabTrackerInfo.funnel_stages"
                        placeholder="Select Stage"
                        select-label=""
                        deselect-label=""
                        label="value"
                        track-by="value"
                    />

                    <div v-else>
                        : {{collabStage.value}}
                    </div>
                </div>

                <template
                    v-if="commonData.has_account && availableRecipients.length
                    && !store.getters.settings.disable_external_messaging
                    && !store.getters.settings.disable_messages"
                >
                    <StartChatPopup
                        ref="startChatModal"
                        :recipients="availableRecipients"
                    />

                    <div class="d-flex">
                        <!-- <a class="link-follow mr-4">Follow</a> -->
                        <a class="link-message" @click="startChatModal.open()">
                            Message
                        </a>
                    </div>
                </template>
            </div>
        </div>

        <div class="topbar__mobile-fit" v-click-outside="() => showActions = false">
            <input type="checkbox" v-model="showActions" class="topbar__mobile-fit__switcher"/>
            <div class="mt-lg-4 topbar__inner">

                <ul class="actions-list ml-auto">
                    <li v-if="store.getters.isEnterprise && isStartup" class="actions-list__similar">
                        <a @click="similarStartupsModal.open(companyId)">Similar Startups</a>
                    </li>

                    <li
                        v-if="store.getters.isEnterprise && !$store.getters.settings.disable_tasks"
                        class="actions-list__task"
                    >
                        <a @click="showTasks">Manage Tasks ({{tasksCount}})</a>
                    </li>
                </ul>

                <ul class="topbar__side actions-list ml-4">
                    <li>
                        <b-dropdown text="Export" class="topbar__side__actions topbar__side__actions--export">
                            <b-dropdown-item
                                v-if="isStartup"
                                class="action action--pdf"
                                @click="exportPdfModal.open([companyId], commonData.name)"
                            >
                                Export PDF
                            </b-dropdown-item>

                            <template v-if="store.getters.isEnterprise">
                                <template v-if="isStartup">
                                    <b-dropdown-item class="action action--ppt" @click="exportPPT(false)">
                                        Export PPT
                                    </b-dropdown-item>

                                    <b-dropdown-item v-if="store.getters.settings.allow_advanced_ppt_export" class="action action--ppt" @click="exportPPT(true)">
                                        Export PPT (Advanced)
                                    </b-dropdown-item>
                                </template>

                                <b-dropdown-item v-if="!commonData.embargoed_country" class="action action--csv" @click="exportContacts">
                                    Export Contacts CSV
                                </b-dropdown-item>
                            </template>
                        </b-dropdown>
                    </li>

                    <li v-if="store.getters.isEnterprise">
                        <b-dropdown text="More" class="topbar__side__actions">
                            <b-dropdown-item @click="emit('reportIncorrectData')" class="action icons-list__report">
                                Report Incorrect Data
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-if="isStartup && store.state.accountType === ACCOUNT_TYPE_PREMIUM" @click="emit('inviteCompany')"
                                class="action icons-list__invite white-space-normal"
                            >
                                Invite Startup To Edit Profile
                            </b-dropdown-item>
                        </b-dropdown>
                    </li>
                </ul>
            </div>
        </div>

        <AddToTrackers
            v-if="trackersIds !== null"
            ref="addToTrackersModal"
            :trackers-list="allTrackersList"
            :exclude-trackers-list="trackersIds"
            @trackersSelected="addToTrackers"
            @modalClosed="addToTrackersClosed"
        />

        <SimilarStartups ref="similarStartupsModal"/>

        <ExportPdf
            ref="exportPdfModal"
            table-type="profile"
        />

        <TrackersListModal
            ref="trackersListModal"
            @addToTracker="addTrackerFromModal"
            @trackerRemoved="getTrackersIds"
        />

        <TasksList
            ref="tasksListModal"
            @tasksChanged="getTasksCount"
        />
    </div>
</template>

<style scoped>
</style>
