<script setup>
import {useStore} from "vue2-helpers/vuex";
import {computed, provide, inject} from "vue";
import Dashboard from "@/views/enterprise/home/content/Dashboard.vue";
import Tasks from "@/views/enterprise/home/content/Tasks.vue";
import Startups from "@/views/enterprise/home/content/Startups.vue";
import Searches from "@/views/enterprise/home/content/searches/Searches.vue";
import Catogories from "@/views/enterprise/home/content/Catogories.vue";
import Trending from "@/views/enterprise/home/content/trending/Trending.vue";

const store = useStore();
const filters = inject('filtersForServer');

const layout = computed(() => store.getters['homepage/layout']);

const layoutLines = computed(() => {
    let lines = [];

    if (layout.value) {
        // Ensure than 'searches' section always follows 'startups' section
        let resortedLayout = [...layout.value];
        const startupsIndex = resortedLayout.indexOf('startups');
        const searchesIndex = resortedLayout.indexOf('searches');

        if (startupsIndex >= 0 && searchesIndex >= 0 && searchesIndex !== startupsIndex + 1) {
            resortedLayout.splice(searchesIndex, 1);
            resortedLayout.splice(startupsIndex + 1, 0, 'searches');
        }


        layout.value.forEach(section => {
            let line = [];
            line.push(section);

            // 'startups' and 'searches' sections should be in the same line
            if (section === 'startups' && searchesIndex >= 0) {
                line.push('searches');
            }

            if (section === 'searches' && startupsIndex >= 0) {
                return;
            }

            lines.push(line);
        });
    }

    return lines;
});

provide('filters', filters);
</script>

<template>
    <div class="content">
        <!--section class="dashboard--events">
            <div class="card card--bordered card--dashboard mt-5">
                <header class="dashboard__header">
                    <h3 class="heading3">Upcoming Events</h3>
                    <a class="link link--view">Show more</a>
                </header>

                <ul class="list-cards-inrow list-cards-inrow--in3">
                    <li>
                        <figure class="list-cards-inrow__figure">
                            <img src="https://sp-images-prod.s3.amazonaws.com/assets/dashboard/TopInvestorsChart.png" alt="">
                        </figure>

                        <div class="list-cards-inrow__subject">
                            <h5 class="heading5">Proin Fermentum Leo Vel</h5>
                            
                            <p>Id porta nibh venenatis cras sed. Eu tincidunt tortor aliquam nulla facilisi cras fermentum.</p>

                            <div>
                                <a href="#" class="link link--arrow">Read More</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <figure class="list-cards-inrow__figure">
                            <img src="https://sp-images-prod.s3.amazonaws.com/assets/dashboard/TopInvestorsChart.png" alt="">
                        </figure>

                        <div class="list-cards-inrow__subject">
                            <h5 class="heading5">Proin Fermentum Leo Vel</h5>
                            
                            <p>Id porta nibh venenatis cras sed. Eu tincidunt tortor aliquam nulla facilisi cras fermentum.</p>

                            <div>
                                <a href="#" class="link link--arrow">Read More</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <figure class="list-cards-inrow__figure">
                            <img src="https://sp-images-prod.s3.amazonaws.com/assets/dashboard/TopInvestorsChart.png" alt="">
                        </figure>

                        <div class="list-cards-inrow__subject">
                            <h5 class="heading5">Proin Fermentum Leo Vel</h5>
                            
                            <p>Id porta nibh venenatis cras sed. Eu</p>

                            <a href="#" class="link link--arrow">Read More</a>
                        </div>
                    </li>
                </ul>
            </div>
        </section-->

        <div v-for="(line, lineIndex) in layoutLines" :key="lineIndex">
            <div class="">
                <div :class="{'widget-half': line.length > 1 && (section === 'startups' || section === 'searches'),
                    'widget-full': line.length === 1 && (section === 'startups' || section === 'searches'), }"
                    v-for="(section, sectionIndex) in line"
                    :key="sectionIndex"
                >
                    <Dashboard v-if="section === 'dashboard'"/>
                    <Tasks v-if="section === 'tasks' && !store.getters.settings.disable_tasks"/>
                    <Startups v-if="section === 'startups'"/>
                    <Searches v-if="section === 'searches'"/>
                    <Trending v-if="section === 'trending'"/>
                    <Catogories v-if="section === 'categories'"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
