<script setup>
import {constants} from "@/views/search/searchConstants";
import Vue, {inject, ref} from "vue";
import tagsService from "@/services/tags.service";
import exportsService from "@/services/exports.service";
import fileHelper from "@/helpers/file";
import ManageColumns from "@/components/manage/ManageColumns.vue";
import AddTags from "@/components/manage/AddTags.vue";
import RemoveTags from "@/components/manage/RemoveTags.vue";
import ExportPdf from "@/components/export/ExportPdf.vue";
import {useStore} from "vue2-helpers/vuex";
import {useRoute} from "vue2-helpers/vue-router";
import enterprisesService from "@/services/ecosystem/enterprises.service";
import string from "@/helpers/string";

const store = useStore();
const manageColumnsModal = ref(null);
const addTagsModal = ref(null);
const removeTagsModal = ref(null);
const exportPdfModal = ref(null);

const {gridParams,...props} = defineProps(['searchType', 'searchParams', 'elasticQuery', 'suggestedAiParams', 'gridParams', 'allQuery']);
const emit = defineEmits(['getList','openAiModal','getColumns']);
const route = useRoute()

const getColumns = inject('getColumns');

function copyQueryToClipboard() {
    navigator.clipboard.writeText(props.elasticQuery)
}

async function addTags(tags) {
    let tagsIds = tags.map(item => item.id)

    if (gridParams.selectedAllResults) {
        await tagsService.addTagsByQuery(props.allQuery, props.searchParams, gridParams.sort, tagsIds);
    } else {
        await tagsService.addTags(gridParams.selectedRows, tagsIds);
    }

    addTagsModal.value.close();
    emit('getList');
}

async function removeTags(tags) {
    let tagsIds = tags.map(item => item.id)

    if (gridParams.selectedAllResults) {
        await tagsService.removeTagsByQuery(props.allQuery, props.searchParams, gridParams.sort, tagsIds);
    } else {
        await tagsService.removeTags(gridParams.selectedRows, tagsIds);
    }

    removeTagsModal.value.close();
    emit('getList');
}

async function exportCSV() {
    const {data} = await (
        gridParams.selectedAllResults
            ? exportsService.exportCompaniesByQuery(props.searchType, props.allQuery, props.searchParams, gridParams.sort, gridParams.columns)
            : exportsService.exportCompanies(props.searchType, gridParams.selectedRows, gridParams.columns)
    );

    fileHelper.download(data, props.searchType + '.csv')
}

function openExportPdf() {
    if (gridParams.selectedAllResults) {
        exportPdfModal.value.openForQuery('startups', {query: props.allQuery, params: props.searchParams})
    } else {
        exportPdfModal.value.openForMultiple('startups', gridParams.selectedRows)
    }
}

async function exportPPT(advanced) {
    const {data} = await (
        gridParams.selectedAllResults
            ? exportsService.generateStartupsPpt(advanced, [], [], {query: props.allQuery, params: props.searchParams})
            : exportsService.generateStartupsPpt(advanced, gridParams.selectedRows)
    );

    fileHelper.download(data, props.searchType + '.pptx')
}

async function connect() {
    let postData = {}

    if (gridParams.selectedAllResults) {
        postData.query = props.allQuery;
        postData.params = props.searchParams;
    } else {
        postData.selected = gridParams.selectedRows;
    }

    const {data} = await enterprisesService.bulkConnect(postData);
    let message = []

    if (data.invited > 0) {
        message.push("Invited: " + data.invited + " " + string.getNoun('enterprise', data.invited))
    }

    if (data.requested > 0) {
        message.push("Sent Connection Request for " + data.requested + " " + string.getNoun('enterprise', data.requested))
    }

    console.log('connect');
    emit('getList');

    Vue.notify({
        group: 'app',
        type: 'success',
        duration: 5000,
        text: message.join(', ')
    })
}

</script>

<template>
    <li>
        <div>
            <b-dropdown id="dropdown-1" text="Actions" class="topbar__side__actions">
                <b-dropdown-item
                    class="action action--edit"
                    @click="manageColumnsModal.open()"
                >
                    Edit Columns
                </b-dropdown-item>

                <b-dropdown-item
                    v-if="route.query['debug'] && elasticQuery"
                    @click="copyQueryToClipboard"
                >
                    Copy query
                </b-dropdown-item>

                <b-dropdown-item
                    v-if="route.query['debug'] && suggestedAiParams"
                    @click="emit('openAiModal')"
                >
                    Show Params
                </b-dropdown-item>

                <template v-if="gridParams.selectedRows.length">
                    <b-dropdown-item
                        v-if="store.getters.isEnterprise"
                        class="action action--tag"
                        @click="addTagsModal.open()"
                    >
                        Add Tags
                    </b-dropdown-item>

                    <b-dropdown-item
                        v-if="store.getters.isEnterprise"
                        class="action action--remove"
                        @click="removeTagsModal.open()"
                    >
                        Remove Tags
                    </b-dropdown-item>

                    <template v-if="store.state.realAccountType !== 'trial'">
                        <b-dropdown-item
                            class="action action--csv"
                            @click="exportCSV"
                        >
                            Export CSV
                        </b-dropdown-item>

                        <template
                            v-if="searchType === constants.SEARCH_TYPE_STARTUPS
                                                && !(gridParams.selectedAllResults && gridParams.totalRowsCount > 100)
                                                && gridParams.selectedRows.length <= 100"
                        >
                            <b-dropdown-item
                                class="action action--pdf"
                                @click="openExportPdf"
                            >
                                Export PDF
                            </b-dropdown-item>

                            <b-dropdown-item
                                class="action action--ppt"
                                @click="exportPPT(false)"
                            >
                                Export PPT
                            </b-dropdown-item>

                            <b-dropdown-item
                                v-if="store.getters.settings.allow_advanced_ppt_export"
                                class="action action--ppt"
                                @click="exportPPT(true)"
                            >
                                Export PPT (Advanced)
                            </b-dropdown-item>
                        </template>
                    </template>

                    <b-dropdown-item
                        v-if="searchType === constants.SEARCH_TYPE_ENTERPRISES && ['premium','standard','trial'].includes(store.state.user.account_type)"
                        class="action"
                        @click="connect"
                    >
                        <font-awesome-icon :icon="['fa', 'plus']" />
                        Request Invite & Connect
                    </b-dropdown-item>
                </template>
            </b-dropdown>
        </div>

        <ManageColumns
            ref="manageColumnsModal"
            :tableType="'search-' + searchType"
            @changeColumns="getColumns"
        />

        <AddTags
            ref="addTagsModal"
            :tags-list="store.getters.tagsList"
            @addTags="addTags"
        />

        <RemoveTags
            ref="removeTagsModal"
            :tags-list="store.getters.tagsList"
            @removeTags="removeTags"
        />

        <ExportPdf
            ref="exportPdfModal"
            :tableType="'search-' + searchType"
        />
    </li>
</template>

<style scoped>

</style>
