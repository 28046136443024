<script setup>
import Vue, {computed, ref, watch} from "vue";
import trackersService from "@/services/trackers.service";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

const showModal = ref(false);
const startups = ref([]);
const features = ref([]);
const recommendedFeatures = ref([]);
const newFeature = ref("");

const loading = ref(false);
const executionId = ref(null);
const reportStatus = ref(null);
const interval = ref(null);
const resultText = ref(null);

const actualRecommendedFeatures = computed(() => {
    return recommendedFeatures.value.filter(feature => !features.value.includes(feature));
});

watch(showModal, (value) => {
    if (!value) {
        clearInterval(interval.value);
    }
});

async function sendRequest() {
    loading.value = true;
    const {data} = await trackersService.getComparisonReport(startups.value, features.value);

    reportStatus.value = 'processing';
    executionId.value = data;
    loading.value = false;

    Vue.notify({
        group: 'app',
        type: 'success',
        text: 'Request sent'
    });

    interval.value = setInterval(async () => {
        await checkReportStatus();
    }, 30000);
}

async function checkReportStatus() {
    const {data} = await trackersService.checkComparisonReportExecution(executionId.value);

    if (data.status !== 'processing') {
        clearInterval(interval.value);
    }

    if ('text' in data) {
        resultText.value = data.text;
    }

    Vue.notify({
        group: 'app',
        type: 'success',
        text: 'Request status: ' + data.status
    });
}

async function getRecommendedFeatures() {
    const {data} = await trackersService.getRecommendedFeatures(startups.value);
    recommendedFeatures.value = data;
}

function addFeature() {
    if (newFeature.value.length) {
        features.value.push(newFeature.value);
        newFeature.value = "";
    }
}

function open(startupsIds) {
    reportStatus.value = null;
    resultText.value = null;
    showModal.value = true;
    startups.value = startupsIds;
    getRecommendedFeatures();
}

defineExpose({
    open
});

</script>

<template>
    <b-modal v-model="showModal" title="Compare Features" modal-class="modal-w-xl" :hide-footer="reportStatus !== null">
        <div>Add features you want to compare</div>

        <div style="display: flex; justify-content: space-between; margin-top: 20px">
            <div style="flex: 1">
                <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;">
                    <div style="flex: 1">
                        <input
                            v-model="newFeature"
                            type="text"
                            placeholder="Enter features"
                            class="form-control form-control--mid"
                            @keyup.enter="addFeature"
                        >
                    </div>
                    <div style="width: 50px; text-align: right">
                        <a class="link" @click="addFeature">Add</a>
                    </div>
                </div>

                <ul style="margin-right: 50px">
                    <li
                        v-for="(feature, index) in features"
                        :key="index"
                        class="feature-item"
                    >
                        <div style="display: flex; justify-content: space-between; align-items: center;">
                            <div>
                                {{feature}}
                            </div>

                            <div>
                                <a @click="features.splice(index, 1)" class="remove-button"></a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div style="padding-left: 70px">
                <div class="recommended-features-block">
                    <div style="text-transform: uppercase; text-align: center; font-weight: bold; margin-bottom: 30px;">
                        Suggestions
                    </div>

                    <ul>
                        <li
                            v-for="(feature, index) in actualRecommendedFeatures"
                            :key="index"
                            class="feature-item"
                        >
                            <div style="display: flex; justify-content: space-between; align-items: center;">
                                <div>
                                    {{feature}}
                                </div>

                                <div>
                                    <a @click="features.push(feature)" class="add-button"></a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-html="resultText"/>

        <template #modal-footer>
            <PrimaryButton
                v-if="reportStatus === null"
                :loading="loading"
                :disabled="!features.length"
                caption="Generate Report"
                loading-caption="Sending request..."
                @click="sendRequest"
            />
        </template>
    </b-modal>
</template>

<style scoped>
    .feature-item {
        padding: 10px 0;

        &:not(:last-child) {
            border-bottom: 1px solid #ddd;
        }
    }

    .remove-button {
        display: block;
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        background: url(../../images/icon-custom-remove-dark.svg) no-repeat center;
        background-size: contain;
    }

    .recommended-features-block {
        width: 270px;
        padding: 20px;
        background-color: #f2f2f2;
        border-radius: 10px;
    }

    .add-button {
        display: block;
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        background: url(../../images/icon-add-light.svg) no-repeat center;
        background-size: contain;
    }
</style>
