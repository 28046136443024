<script setup>
import Vue, {ref} from "vue";
import trackersService from "@/services/trackers.service";
import {download} from "@/helpers/file";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

const props = defineProps(["id", "name"]);
const showModal = ref(false);
const insightsReportLoading = ref(false);
const marketReportLoading = ref(false);

async function getInsightsReport() {
    insightsReportLoading.value = true;

    try {
        const {data} = await trackersService.getInsightsReport(props.id);

        if (data.size < 100) {
            Vue.notify({
                group: 'app',
                type: 'error',
                duration: 5000,
                text: "Error occurred. Please try again later."
            })
        } else {
            let currentDate = new Date();
            download(data, `${props.name}-${currentDate.toLocaleDateString()}.pdf`);
        }
    } finally {
        insightsReportLoading.value = false;
    }
}

async function getMarketReport() {
    marketReportLoading.value = true;

    try {
        const {data} = await trackersService.getMarketReport(props.id);

        if (data.size < 100) {
            Vue.notify({
                group: 'app',
                type: 'error',
                duration: 5000,
                text: "Error occurred. Please try again later."
            })
        } else {
            let currentDate = new Date();
            download(data, `${props.name}-${currentDate.toLocaleDateString()}.pdf`);
        }
    } finally {
        marketReportLoading.value = false;
    }
}

function open() {
    showModal.value = true;
}

defineExpose({
    open
});
</script>

<template>
    <b-modal v-model="showModal" hide-footer title="Generate Tracker Report" modal-class="modal-tracker-report">
        <p>Select a template to generate:</p>
        <ul class="list-report mb-5">
            <li>
                <figure class="list-report__figure">
                    <img src="@/images/tracker-report-templates-previews/comprehensive-report.jpeg"/>
                </figure>

                <h5 class="heading5">Comprehensive Report</h5>

                <p>Future trends, recommendations and similar startups</p>
                <p><b>Best for: Landscaping new areas</b></p>

                <PrimaryButton
                    caption="Select Template"
                    loading-caption="Generating..."
                    :loading="insightsReportLoading"
                    :disabled="insightsReportLoading || marketReportLoading"
                    spinner
                    class="btn btn-bordered"
                    @click="getInsightsReport"
                />
            </li>

            <li>
                <figure class="list-report__figure">
                    <img src="@/images/tracker-report-templates-previews/high-level-overview.jpeg"/>
                </figure>

                <h5 class="heading5">High level overview</h5>
                <p>Market & Industry Analysis, Startup Landscape Analysis, Recommendations & Strategic Implications</p>

                <PrimaryButton
                    caption="Select Template"
                    loading-caption="Generating..."
                    :loading="marketReportLoading"
                    :disabled="insightsReportLoading || marketReportLoading"
                    spinner
                    class="btn btn-bordered"
                    @click="getMarketReport"
                />
            </li>
        </ul>
    </b-modal>
</template>

<style scoped>

</style>
